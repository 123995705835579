
        <template>
          <div class="vc-snippet-doc">
            <h2>Tree 巨树</h2>
<p>巨树：基于ztree封装的Vue树形组件，轻松实现海量数据的高性能渲染。
支持同时渲染多个树。</p>
<h3>基础用法</h3>
<p>基础的用法。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-tree</span> <span class="hljs-attr">:nodes</span>=<span class="hljs-string">"nodesFlat"</span>
        <span class="hljs-attr">:setting</span>=<span class="hljs-string">"settingFlat"</span> 
        @<span class="hljs-attr">on-click</span>=<span class="hljs-string">"onClick"</span>
        @<span class="hljs-attr">on-check</span>=<span class="hljs-string">"onCheck"</span>
        @<span class="hljs-attr">on-created</span>=<span class="hljs-string">"handleCreated"</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-tree</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
<span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
        <span class="hljs-keyword">return</span> {
            <span class="hljs-attr">settingFlat</span>: {
                <span class="hljs-attr">check</span>: {
                    <span class="hljs-attr">enable</span>: <span class="hljs-literal">true</span>
                },
                <span class="hljs-attr">data</span>: {
                    <span class="hljs-attr">simpleData</span>: {
                        <span class="hljs-attr">enable</span>: <span class="hljs-literal">true</span>,
                        <span class="hljs-attr">idKey</span>: <span class="hljs-string">"id"</span>,
                        <span class="hljs-attr">pIdKey</span>: <span class="hljs-string">"pid"</span>,
                        <span class="hljs-attr">rootPId</span>: <span class="hljs-string">"0"</span>
                    }
                }
            },
            <span class="hljs-attr">nodesFlat</span>: [
                { <span class="hljs-attr">id</span>:<span class="hljs-number">1</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">0</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 1"</span>, <span class="hljs-attr">open</span>:<span class="hljs-literal">true</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">11</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">1</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 1-1"</span>, <span class="hljs-attr">open</span>:<span class="hljs-literal">true</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">111</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">11</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 1-1-1"</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">112</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">11</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 1-1-2"</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">12</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">1</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 1-2"</span>, <span class="hljs-attr">open</span>:<span class="hljs-literal">true</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">121</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">12</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 1-2-1"</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">122</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">12</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 1-2-2"</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">2</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">0</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 2"</span>, <span class="hljs-attr">checked</span>:<span class="hljs-literal">true</span>, <span class="hljs-attr">open</span>:<span class="hljs-literal">true</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">21</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">2</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 2-1"</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">22</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">2</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 2-2"</span>, <span class="hljs-attr">open</span>:<span class="hljs-literal">true</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">221</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">22</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 2-2-1"</span>, <span class="hljs-attr">checked</span>:<span class="hljs-literal">true</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">222</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">22</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 2-2-2"</span>},
                { <span class="hljs-attr">id</span>:<span class="hljs-number">23</span>, <span class="hljs-attr">pid</span>:<span class="hljs-number">2</span>, <span class="hljs-attr">name</span>:<span class="hljs-string">"随意勾选 2-3"</span>}
            ]
        }
    },
    <span class="hljs-attr">methods</span>: {
        onClick(evt, treeId, treeNode) {
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'onClick'</span>, evt, treeId, treeNode)
        },
        onCheck(evt, treeId, treeNode) {
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'onCheck'</span>, evt, treeId, treeNode)
        },
        handleCreated(ztreeObj) {
            <span class="hljs-built_in">console</span>.log(<span class="hljs-string">'handleCreated'</span>, ztreeObj)
        }
    }
}
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>setting</td>
<td>ztree 配置</td>
<td>Object</td>
<td>—</td>
<td><code>{view: {showIcon: false}}</code></td>
</tr>
<tr>
<td>nodes</td>
<td>ztree 数据</td>
<td>Array</td>
<td>—</td>
<td><code>[]</code></td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           //
//
//
//
//
//
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-tree',{attrs:{"nodes":_vm.nodesFlat,"setting":_vm.settingFlat},on:{"on-click":_vm.onClick,"on-check":_vm.onCheck,"on-created":_vm.handleCreated}})}
var staticRenderFns = []

          const exportJavaScript =  {
    data() {
        return {
            settingFlat: {
                check: {
                    enable: true
                },
                data: {
                    simpleData: {
                        enable: true,
                        idKey: "id",
                        pIdKey: "pid",
                        rootPId: "0"
                    }
                }
            },
            nodesFlat: [
                { id:1, pid:0, name:"随意勾选 1", open:true},
                { id:11, pid:1, name:"随意勾选 1-1", open:true},
                { id:111, pid:11, name:"随意勾选 1-1-1"},
                { id:112, pid:11, name:"随意勾选 1-1-2"},
                { id:12, pid:1, name:"随意勾选 1-2", open:true},
                { id:121, pid:12, name:"随意勾选 1-2-1"},
                { id:122, pid:12, name:"随意勾选 1-2-2"},
                { id:2, pid:0, name:"随意勾选 2", checked:true, open:true},
                { id:21, pid:2, name:"随意勾选 2-1"},
                { id:22, pid:2, name:"随意勾选 2-2", open:true},
                { id:221, pid:22, name:"随意勾选 2-2-1", checked:true},
                { id:222, pid:22, name:"随意勾选 2-2-2"},
                { id:23, pid:2, name:"随意勾选 2-3"}
            ]
        }
    },
    methods: {
        onClick(evt, treeId, treeNode) {
            console.log('onClick', evt, treeId, treeNode)
        },
        onCheck(evt, treeId, treeNode) {
            console.log('onCheck', evt, treeId, treeNode)
        },
        handleCreated(ztreeObj) {
            console.log('handleCreated', ztreeObj)
        }
    }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>